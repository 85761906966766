import React, { FunctionComponent } from "react";
import SliderBlock from "../SliderBlock";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import "swiper/scss";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { createThemeClassName } from "../../utils/themeClass";

export interface ArticleSliderInterface {
  heading?: string;
  bgColor?: {
    title: string;
  };
  secondaryHeading?: string;
  secHeadingColor?: {
    title: string;
  };
  content: {
    _id: string;
    _rawHeroImage: ImageInterface;
    slug: {
      current: string;
    };
    headline: string;
    parentSection: Slug & {
      parentSection?: Slug;
    };
    // For Preview
    heroImage?: ImageInterface;
  }[];
  themeColor?: string;
  theme: {
    name: string;
  };
}

const ArticleSlider: FunctionComponent<ArticleSliderInterface> = ({
  heading,
  bgColor,
  content,
  themeColor,
  secHeadingColor,
  secondaryHeading,
  theme
}) => {
  const titleColor = themeColor?.toLowerCase() || bgColor?.title.toLowerCase();
  const secondaryHeadingColor = secHeadingColor?.title?.toLowerCase() || bgColor?.title.toLowerCase();

  const outColor = theme?.name === "darkteal-piano" ? "#9c9c9c" : "blue";

  const colorList: { [key: string]: string } = {
    purple: "var(--purple-light-color)",
    orange: "var(--orange-bright-color)",
    pink: "var(--pink-bright-color)",
    teal: "var(--teal-color)",
    primary: "var(--primary-color)",
    red: "var(--orange-red-color)",
    blue: "var(--blue-dark-color)",
    "light blue": "var(--blue-bright-color)",
    white: "var(--white-color)"
  };
  return (
    <section
      className={`article-slider ${createThemeClassName(theme?.name)} ${secondaryHeading ? "article-slider-home" : ""}`}
      data-testid="article-slider"
    >
      <Container fluid>
        {heading && (
          <h2 style={{ color: colorList[titleColor as string] }} data-testid="article-slider-heading">
            {heading}{" "}
            {secondaryHeading && (
              <span style={{ color: colorList[secondaryHeadingColor as string] }}>
                <br />
                {secondaryHeading}
              </span>
            )}
          </h2>
        )}
        {content && (
          <SliderBlock
            data={content}
            productColor={outColor}
            textColor={colorList[titleColor as string]}
            type="article"
          />
        )}
      </Container>
    </section>
  );
};

export default ArticleSlider;
